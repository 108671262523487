'use strict';

angular.module('adminApp', ['ngCookies', 'ngResource', 'ngSanitize', 'ngRoute', 'ngAnimate', 'ui.router', 'ui.bootstrap', 'ui.select', 'LocalStorageModule', 'treeControl', 'ui.layout', 'textAngular', 'angularMoment']).constant('defaultQueryParams', { page: 1, pageSize: 20, countLimitOption: { enabled: false } }).constant('exportToExcelTaskParams', { refreshMSec: 1000 }).config(["$stateProvider", "$urlRouterProvider", "$tooltipProvider", "$compileProvider", function ($stateProvider, $urlRouterProvider, $tooltipProvider, $compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|makecall):/);
    var WS_URI = '/ws/api';

    $tooltipProvider.setTriggers({
        'mouseenter': 'mouseleave',
        'click': 'click',
        'focus': 'blur',
        'show': 'hide'
    });

    var managedViewState = function managedViewState(stateName, particularViewStateManagerName) {
        return function (ViewStateManager, $stateParams) {
            return ViewStateManager.resolve(particularViewStateManagerName, stateName, $stateParams);
        };
    };

    $stateProvider.state('app', {
        controller: 'RootCtrl',
        templateUrl: 'views/root.html',
        abstract: true
    }).state('app.welcome', {
        templateUrl: 'views/welcome.html',
        controller: 'WelcomeCtrl',
        abstract: true,
        resolve: {
            session: ['$q', '$http', function ($q, $http) {
                var deferred = $q.defer();
                $http.get('/ws/api/external-cc/loggedin').then(function (response) {
                    deferred.resolve({ loggedin: '' + response.data });
                }, function () {
                    return deferred.resolve({ loggedin: 'false' });
                });
                return deferred.promise;
            }]
        }
    }).state('app.welcome.login', {
        url: '/login',
        templateUrl: 'views/login-form.html',
        controller: 'LoginCtrl'
    }).state('app.welcome.logout', {
        url: '/logout',
        template: '',
        controller: 'LogoutCtrl',
        resolve: {
            LoggedOut: ['User', function (User) {
                return User.getLogoutPromise();
            }]
        }
    }).state('app.admin', {
        templateUrl: 'views/admin.html',
        controller: 'AdminCtrl',
        abstract: true
    }).state('app.admin.home', {
        url: '/home',
        templateUrl: 'views/home.html',
        controller: 'HomeCtrl'
    }).state('app.admin.phone-call-application', {
        url: '/phone-call-application?mobilePhone&autoConfirm',
        templateUrl: 'views/phone-call-application.html',
        controller: 'PhoneCallApplicationCtrl',
        resolve: {
            ViewState: managedViewState('app.phone-call-application', 'PhoneCallApplicationCtrlViewStateManager'),
            ExternalCompanyDetails: ['$http', function ($http) {
                return $http.get(WS_URI + '/external-cc-users/loggedin');
            }]
        },
        title: function title() {
            return 'Phone call application';
        },
        addInHistory: true
    }).state('app.admin.phone-call-additional-amount-application', {
        url: '/phone-call-additional-amount-application?loanId&clientId',
        templateUrl: 'views/phone-call-additional-amount-application.html',
        controller: 'PhoneCallAdditionalAmountApplicationCtrl',
        resolve: { ViewState: managedViewState('app.phone-call-additional-amount-application', 'PhoneCallAdditionalAmountApplicationCtrlViewStateManager') },
        title: function title() {
            return 'Additional amount application';
        },
        addInHistory: true
    }).state('app.admin.phone-call-refinance-application', {
        url: '/phone-call-refinance-application?clientId',
        templateUrl: 'views/phone-call-refinance-application.html',
        controller: 'PhoneCallRefinanceApplicationCtrl',
        resolve: { ViewState: managedViewState('app.phone-call-refinance-application', 'PhoneCallRefinanceApplicationCtrlViewStateManager') },
        title: function title() {
            return 'Refinance application';
        },
        addInHistory: true
    }).state('app.admin.system-dashboard', {
        url: '/system-dashboard',
        templateUrl: 'views/system-dashboard.html',
        controller: 'SystemDashboardCtrl',
        //resolve: {ViewState: managedViewState('app.admin.system-dashboard', 'LoanApplicationsCtrlViewStateManager')},
        title: function title() {
            return 'System dashboard';
        },
        addInHistory: true
    });

    $urlRouterProvider.otherwise(function ($injector) {
        $injector.invoke(['User', '$state', function (User, $state) {
            if (User.isAuthenticated()) {
                $state.go('app.admin.home');
            } else {
                $state.go('app.welcome.login');
            }
        }]);
    });
}]).run(["$rootScope", "$state", "$stateParams", "$timeout", "User", "ViewHistory", function ($rootScope, $state, $stateParams, $timeout, User, ViewHistory) {
    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;
    $rootScope.$app = {};
    $rootScope.POLISH_NETCREDIT = 'Net Credit Sp. z o.o.';
    $rootScope.POLISH_INCREDIT = 'InCredit Sp. z o.o.';

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
        // check if user is authenticated
        if (!User.isAuthenticated() && toState.name.indexOf('app.welcome') !== 0) {
            event.preventDefault();
            // not authenticated, remember initial state and go to sign in page
            $rootScope.$app.initialState = { state: toState, params: toParams };
            $timeout(function () {
                return event.targetScope.$apply(function () {
                    return $state.go('app.welcome.login');
                });
            }, 300);
        }
    });

    ViewHistory.makeSureIsLoaded();
}]);
'use strict';

angular.module('adminApp').filter('elapsed', function () {
    return function (val, humanize) {
        if (!val) {
            return '';
        }

        var now = moment();
        var date = moment(val);
        if (date.diff(now, 'days') > -2) {
            var diff = date.diff(now);
            var duration = moment.duration(diff);
            return duration.humanize(humanize);
        } else {
            return date.format('MMM D');
        }
    };
});
'use strict';

angular.module('adminApp').filter('truncate', function () {
    return function (text, length, end) {
        if (!text) {
            return '';
        }

        if (isNaN(length)) {
            length = 10;
        }

        if (end === undefined) {
            end = '...';
        }

        if (text.length <= length || text.length - end.length <= length) {
            return text;
        } else {
            return String(text).substring(0, length - end.length) + end;
        }
    };
});
'use strict';

angular.module('adminApp').filter('unsafe', ["$sce", function ($sce) {
    return function (val) {
        return $sce.trustAsHtml(val);
    };
}]);
'use strict';

angular.module('adminApp').filter('jsonTime', ['Util', function (Util) {
    return function (jsonDateArray) {
        return Util.jsonTimeFormat(jsonDateArray);
    };
}]).filter('jsonDate', ['Util', function (Util) {
    return function (jsonDateArray) {
        return Util.jsonDateFormat(jsonDateArray);
    };
}]).filter('jsonTimeWithSeconds', ['Util', function (Util) {
    return function (jsonDataArray) {
        return Util.jsonTimeWithSecondsFormat(jsonDataArray);
    };
}]).filter('jsonTimeDifference', ['Util', function (Util) {
    return function (date1, date2) {
        if (date1 === null) {
            return "";
        }
        if (date2 === undefined) {
            date2 = Util.dateTimeToJson(new Date());
        }
        return Util.jsonTimeDifference(date2, date1);
    };
}]).filter('jsonDateDaysDifference', ['Util', function (Util) {
    return function (date1, date2) {
        if (date1 === null || date1 === undefined) {
            return "";
        }
        if (date2 === undefined) {
            date2 = Util.dateToJsonDateOnly(new Date());
        }
        return Util.jsonDateDaysDifference(date2, date1);
    };
}]);
'use strict';

(function (angular) {
    'use strict';

    angular.module('adminApp').filter('capitalize', [function () {
        return function (input) {
            return !!input ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
        };
    }]).filter('jsonPrettyPrint', ['Util', function (Util) {
        return function (input) {
            return Util.jsonPrettyPrint(input);
        };
    }]);
})(window.angular);
'use strict';

angular.module('adminApp').service('Heartbeat', ['Errors', 'User', '$timeout', '$http', function (Errors, User, $timeout, $http) {
    var checkTimeout = 10000;
    var timer;
    var heartBeat = {};

    var checkSession = function checkSession() {
        $http.get('/ws/api/external-cc/loggedin').then(function (response) {
            Errors.clearAllErrors();
            if ('' + response.data === 'true' && !User.isAuthenticated()) {
                User.loadUser();
            }
            if ('' + response.data === 'false' && User.isAuthenticated()) {
                Errors.reportError('sessionExpired');
            }
        }, function (response) {
            if (response.status === 401 || response.status === 403) {
                Errors.reportError('sessionExpired');
            } else if (response.status === 0 || response.status >= 404 || response.status >= 500) {
                Errors.reportError('connectionLost');
            }
        });
    };

    var refresh = function refresh() {
        try {
            checkSession();
        } finally {}
        timer = $timeout(refresh, checkTimeout);
    };

    heartBeat.startPolling = function () {
        timer = $timeout(refresh, checkTimeout);
    };

    heartBeat.stopPolling = function () {
        if (timer) {
            $timeout.cancel(timer);
        }
    };

    return heartBeat;
}]);
'use strict';

angular.module('adminApp').service('Errors', ['$rootScope', '$timeout', function ($rootScope, $timeout) {
    $rootScope.globalErrors = {};
    var self = this;

    this.reportError = function (name, autoClearError) {
        $rootScope.globalErrors[name] = true;
        if (autoClearError) {
            $timeout(function () {
                self.clearError(name);
            }, 2000);
        }
    };

    this.clearError = function (name) {
        $rootScope.globalErrors[name] = false;
    };

    this.clearAllErrors = function () {
        $rootScope.globalErrors = {};
    };
}]);
'use strict';

angular.module('adminApp').service('ViewHistory', ['$rootScope', '$state', '$injector', function ($rootScope, $state, $injector) {
    var MAX_ENTRY_COUNT = 10;
    var lastHistorySequenceNumber = 0;

    var historyEntries = {};
    var viewStateManagers = {};

    this.getAsViewHistoryEntries = function () {
        return _.map(historyEntries, function (entry) {
            return { uid: entry.uid, title: entry.title, active: entry.active };
        });
    };

    $rootScope.$on('user.logoutSuccess', function () {
        historyEntries = {};
    });

    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams) {
        stateChanged(toState, toParams);
    });

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        var uid = $state.href(fromState, fromParams);
        if (uid in viewStateManagers) {
            var scope = $state.$current.locals['@app.admin'].$scope;
            var viewStateManagerName = viewStateManagers[uid];
            historyEntries[uid].serializedData = $injector.get(viewStateManagerName).suspendViewState(scope);
        }
    });

    this.makeSureIsLoaded = function () {};

    var stateChanged = function stateChanged(newState, newStateParams) {
        var uid = $state.href(newState, newStateParams);

        if (!('addInHistory' in newState) || newState.addInHistory === false) {
            deactivateHistoryEntriesAndNotify();
            return;
        }

        if (!(uid in historyEntries)) {
            if (_.keys(historyEntries).length > MAX_ENTRY_COUNT) {
                removeOldestEntry();
            }

            addNewHistoryEntry(uid, newState, newStateParams);
        }

        activateHistoryEntry(uid);
    };

    var deactivateHistoryEntriesAndNotify = function deactivateHistoryEntriesAndNotify() {
        deactivateHistoryEntries();

        $rootScope.$broadcast('viewHistory.all-deactivated');
    };

    var deactivateHistoryEntries = function deactivateHistoryEntries(historyEntries) {
        _.each(_.values(historyEntries), function (historyEntry) {
            historyEntry.active = false;
        });
    };

    var activateHistoryEntry = function activateHistoryEntry(uid) {
        deactivateHistoryEntries(historyEntries);

        var activatedHistoryEntry = historyEntries[uid];

        activatedHistoryEntry.active = true;
        activatedHistoryEntry.historySequence = lastHistorySequenceNumber++;

        $rootScope.$broadcast('viewHistory.activated', uid);
    };

    function deleteHistoryEntry(uid) {
        delete historyEntries[uid];
        if (uid in viewStateManagers) {
            delete viewStateManagers[uid];
        }
    }

    var removeOldestEntry = function removeOldestEntry() {
        var oldestEntrySequenceNumber = _.min(_.pluck(_.values(historyEntries), 'historySequence'));
        var oldestEntry = _.findWhere(_.values(historyEntries), { historySequence: oldestEntrySequenceNumber });
        deleteHistoryEntry(oldestEntry.uid);

        notifyOnHistoryEntryClose(oldestEntry);

        $rootScope.$broadcast('viewHistory.removed', oldestEntry.uid);
    };

    this.closeCurrentHistoryEntry = function () {
        var activeEntry = _.findWhere(historyEntries, { active: true });
        if (!_.isUndefined(activeEntry)) {
            this.closeHistoryEntry(activeEntry.uid);
        }
    };

    this.closeAllEntries = function () {
        var entryCount = _.keys(historyEntries).length;
        if (entryCount < 1) {
            return;
        }

        this.closeNonActiveEntries();

        this.closeCurrentHistoryEntry();
    };

    this.closeNonActiveEntries = function () {
        var entryCount = _.keys(historyEntries).length;
        if (entryCount < 1) {
            return;
        }

        for (var i = 0; i < entryCount - 1; i++) {
            removeOldestEntry();
        }
    };

    var notifyOnHistoryEntryClose = function notifyOnHistoryEntryClose(historyEntry) {
        var closableState = historyEntry.stateData.state;

        if ('onClose' in closableState) {
            var onCloseFunction = closableState.onClose;
            onCloseFunction($injector, closableState, historyEntry.stateData.stateParams);
        }
    };

    this.closeHistoryEntry = function (uid) {
        var removableHistoryEntry = historyEntries[uid];
        var historyEntryIsActive = removableHistoryEntry.active;

        deleteHistoryEntry(uid);

        notifyOnHistoryEntryClose(removableHistoryEntry);

        if (historyEntryIsActive === true) {
            if (_.keys(historyEntries).length === 0) {
                $state.go('app.admin.home');
            } else {
                var newestEntrySequenceNumber = _.max(_.pluck(_.values(historyEntries), 'historySequence'));
                var newestEntryUID = _.findWhere(_.values(historyEntries), { historySequence: newestEntrySequenceNumber }).uid;
                $rootScope.$broadcast('viewHistory.activated', newestEntryUID);
                this.changeState(newestEntryUID);
            }
        }

        $rootScope.$broadcast('viewHistory.removed', removableHistoryEntry.uid);
    };

    this.getSavedState = function (stateName, $stateParams) {
        var uid = $state.href(stateName, $stateParams);
        if (uid in historyEntries) {
            return historyEntries[uid].serializedData;
        }
        return undefined;
    };

    this.registerViewStateManagerName = function (viewStateManagerName, stateName, $stateParams) {
        var uid = $state.href(stateName, $stateParams);
        viewStateManagers[uid] = viewStateManagerName;
    };

    function addNewHistoryEntry(uid, toState, toParams) {
        var title = null;

        if ('title' in toState) {
            var titleFunction = toState.title;
            title = titleFunction(toParams);
        } else {
            title = 'unnamed';
        }

        var newHistoryEntry = {
            uid: uid,
            active: true,
            stateData: { name: toState.name, state: toState, stateParams: toParams },
            title: title
        };
        historyEntries[uid] = newHistoryEntry;

        $rootScope.$broadcast('viewHistory.added', { uid: uid, title: title, active: true });
    }

    this.changeState = function (uid) {
        //TODO: rename functions - activateHistoryEntry and change state has incorrect naming - one is activated by browser changes, other by clicking on tab
        var targetStateData = historyEntries[uid].stateData;
        $state.go(targetStateData.name, targetStateData.stateParams);
    };
}]);
'use strict';

angular.module('adminApp').service('ViewStateManager', ['ViewHistory', '$injector', function (viewHistory, $injector) {

    this.resolve = function (viewStateManagerName, stateName, $stateParams) {

        var viewStateManager = $injector.get(viewStateManagerName);
        viewHistory.registerViewStateManagerName(viewStateManagerName, stateName, $stateParams);
        var savedState = viewHistory.getSavedState(stateName, $stateParams);
        if (_.isUndefined(savedState)) {
            return viewStateManager.createViewState($stateParams, stateName);
        } else {
            return viewStateManager.restoreViewState($stateParams, savedState);
        }
    };
}]);
'use strict';

angular.module('adminApp').service('ClientService', ['$rootScope', '$http', '$q', '$state', function ($rootScope, $http) {

    this.getClientLastLoan = function (id) {
        return $http.get('ws/api/external-cc/client/' + id + '/last-loan');
    };

    this.getClient = function (id) {
        return $http.get('/ws/api/external-cc/client/' + id);
    };

    this.addRefinanceDiscount = function (clientId, discount) {
        return $http.post('/ws/api/client/' + clientId + '/refinance-discount', discount);
    };
}]);
'use strict';

angular.module('adminApp').service('exceptionListenerLink', [function () {
    var listener = null;

    this.registerListener = function (newListener) {
        listener = newListener;
    };

    this.onException = function (exception) {
        if (!_.isNull(listener)) {
            listener.onException(exception);
        }
    };
}]).factory('$exceptionHandler', ['exceptionListenerLink', '$log', function (exceptionListenerLink, $log) {
    return function (exception, cause) {
        exception.message += ' (caused by "' + cause + '")';
        exceptionListenerLink.onException(exception);
        $log.error(exception);
    };
}]);
'use strict';

angular.module('adminApp').service('ErrorDialog', ['$modal', '$sce', function ($modal, $sce) {
    var ErrorDialogCtrl = ['$scope', '$modalInstance', 'data', function ($scope, $modalInstance, data) {
        $scope.data = data;

        $scope.errorResponseVisible = false;

        if ($scope.data.type === 'text') {
            var error = $scope.data.text;
            if (!_.isUndefined(error.name) && !_.isUndefined(error.message)) {
                $scope.data.text = 'Error name: ' + error.name + '. Error message: ' + error.message;
            }
        } else if ($scope.data.type === 'httpResponse') {
            $scope.checkedHtml = $sce.getTrustedHtml($scope.data.html);
        }

        $scope.ok = function () {
            $modalInstance.close('ok');
        };

        $scope.showErrorResponse = function () {
            $scope.errorResponseVisible = true;
        };
    }];

    var internalShowError = function internalShowError(errorData) {
        var modalInstance = $modal.open({
            templateUrl: 'views/dialogs/error.html',
            controller: ErrorDialogCtrl,
            windowClass: 'occ-modal',
            resolve: {
                data: function data() {
                    return errorData;
                }
            }
        });

        return modalInstance;
    };

    this.showError = function (textContent) {
        return internalShowError({ type: 'text', text: textContent });
    };

    this.showServerError = function (content) {
        if (_.isObject(content) && 'status' in content) {
            var message = '';
            var html = content.data;
            if (_.isObject(html)) {
                message = html.message;
                html = JSON.stringify(html);
            }
            return internalShowError({
                type: 'httpResponse',
                status: content.status,
                statusText: content.statusText,
                html: html,
                message: message
            });
        } else {
            return this.showError(content);
        }
    };
}]);
'use strict';

angular.module('adminApp').service('AskDialog', ['$modal', function ($modal) {
    var AskDialogCtrl = ['$scope', '$modalInstance', 'askParameters', function ($scope, $modalInstance, askParameters) {
        $scope.title = askParameters.title;
        $scope.question = askParameters.question;
        $scope.class = askParameters.class; // default, primary, success, info, warning, danger
        $scope.answer = { reasonDetails: '' };

        $scope.ok = function () {
            $modalInstance.close($scope.answer.reasonDetails);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('cancel');
        };
    }];

    this.askDialog = function (_askParameters) {
        var modalInstance = $modal.open({
            templateUrl: 'views/dialogs/askForReason.html',
            controller: AskDialogCtrl,
            windowClass: 'occ-modal',
            resolve: {
                askParameters: function askParameters() {
                    return _askParameters;
                }
            }
        });

        return modalInstance;
    };
}]);
'use strict';

var ConfirmDialogCtrl = ['$scope', '$modalInstance', 'confirmParameters', '$sce', function ($scope, $modalInstance, confirmParameters, $sce) {
    $scope.title = confirmParameters.title;
    $scope.class = confirmParameters.class; // default, primary, success, info, warning, danger
    $scope.noCancelButton = confirmParameters.noCancelButton;

    $scope.question = confirmParameters.question;
    $scope.isPlaintext = false;

    if (confirmParameters.preFormatted) {
        $scope.isPreFormatted = true;
        $scope.isPlaintext = true;
    } else if (!confirmParameters.showPlaintextIfHtmlFail) {
        $scope.checkedQuestion = $sce.getTrustedHtml($scope.question);
    } else {
        try {
            $scope.checkedQuestion = $sce.getTrustedHtml($scope.question);
        } catch (error) {
            $scope.isPlaintext = true;
        }
    }

    $scope.ok = function () {
        $modalInstance.close();
    };

    $scope.cancel = function () {
        $modalInstance.dismiss();
    };
}];

angular.module('adminApp').service('ConfirmDialog', ['$modal', function ($modal) {

    this.confirmDialog = function (_confirmParameters) {
        var modalInstance = $modal.open({
            templateUrl: 'views/dialogs/confirm.html',
            controller: ConfirmDialogCtrl,
            windowClass: 'occ-modal',
            resolve: {
                confirmParameters: function confirmParameters() {
                    return _confirmParameters;
                }
            }
        });

        return modalInstance;
    };
}]);
'use strict';

angular.module('adminApp').directive('draggableDialogElement', ['$timeout', function ($timeout) {
    return {
        restrict: 'EA',
        scope: {},
        replace: true,
        transclude: true,
        controller: ['$scope', function ($scope) {
            var ctrl = this;

            $scope.cancel = function () {
                $scope.$parent.$dismiss(false);
            };

            this.registerTitle = function (titleElement) {
                ctrl.titleElement = titleElement;
            };

            this.onBodyTransclude = function (transcludeFunction, bodyElement) {
                transcludeFunction($scope.$parent, function (contents) {
                    bodyElement.empty();
                    ctrl.titleElement.empty();

                    angular.forEach(contents, function (node) {
                        if (node.tagName && node.tagName.toLowerCase() === 'dialog-title') {
                            ctrl.titleElement.append(node);
                        } else {
                            bodyElement.append(node);
                        }
                    });
                });
            };
        }],
        templateUrl: 'views/dialogs/draggableDialog.html',
        link: function link(scope, element, attrs) {
            scope.windowClass = attrs.windowClass || '';

            $(element).draggable({ handle: '.panel-heading' });

            $timeout(function () {
                element[0].focus();
            });
        }
    };
}]).directive('dialogTitleTransclude', function () {
    return {
        require: '^draggableDialogElement',
        link: function link($scope, $element, $attrs, dialogElementController) {
            dialogElementController.registerTitle($element);
        }
    };
}).directive('dialogBodyTransclude', function () {
    return {
        require: '^draggableDialogElement',
        link: function link($scope, $element, $attrs, dialogElementController, $transclude) {
            dialogElementController.onBodyTransclude($transclude, $element);
        }
    };
}).provider('DraggableDialog', function () {

    var draggableDialogProvider = {
        $get: ['$injector', '$rootScope', '$q', '$http', '$templateCache', '$controller', '$compile', '$document', function ($injector, $rootScope, $q, $http, $templateCache, $controller, $compile, $document) {

            function DialogPromises(templatePromise, dataResolves) {

                var dataPromisesArr = [];
                angular.forEach(dataResolves, function (value) {
                    var promise = value;
                    if (angular.isFunction(value) || angular.isArray(value)) {
                        promise = $injector.invoke(value);
                    }
                    dataPromisesArr.push($q.when(promise));
                });

                var allPromises = $q.all([templatePromise].concat(dataPromisesArr));

                this.result = allPromises.then(function (promisesData) {
                    var dataPromisesData = {};

                    _.each(_.keys(dataResolves), function (key, index) {
                        dataPromisesData[key] = promisesData[index + 1];
                    });

                    return { template: promisesData[0], dataPromisesData: dataPromisesData };
                });
            }

            function DraggableDialogInstance() {
                this.resultDeferred = $q.defer();
                var self = this;

                this.render = function (renderData) {
                    var angularDomEl = angular.element('<draggable-dialog-element></draggable-dialog-element>');
                    angularDomEl.attr({
                        'window-class': renderData.windowClass
                    }).html(renderData.template);

                    self.domElement = $compile(angularDomEl)(renderData.scope);
                    return self.domElement;
                };

                this.close = function (result) {
                    self.domElement.remove();
                    self.resultDeferred.resolve(result);
                };

                this.dismiss = function (reason) {
                    self.domElement.remove();
                    self.resultDeferred.reject(reason);
                };

                this.getLink = function () {
                    return {
                        result: self.resultDeferred.promise,
                        close: function close(result) {
                            self.close(result);
                        },
                        dismiss: function dismiss(reason) {
                            self.dismiss(reason);
                        }
                    };
                };
            }

            var draggableDialog = {};

            function getTemplatePromise(options) {
                return options.template ? $q.when(options.template) : $http.get(angular.isFunction(options.templateUrl) ? options.templateUrl() : options.templateUrl, { cache: $templateCache }).then(function (result) {
                    return result.data;
                });
            }

            var renderDialogWithData = function renderDialogWithData(dialogInstance, dialogOptions, dialogPromisesData) {
                var dialogScope = (dialogOptions.scope || $rootScope).$new();
                dialogScope.$close = dialogInstance.close;
                dialogScope.$dismiss = dialogInstance.dismiss;

                if (dialogOptions.controller) {
                    var ctrlInstance = {};

                    var ctrlLocals = dialogPromisesData.dataPromisesData;
                    ctrlLocals.$scope = dialogScope;
                    ctrlLocals.draggableDialogInstance = dialogInstance;

                    ctrlInstance = $controller(dialogOptions.controller, ctrlLocals);
                    if (dialogOptions.controllerAs) {
                        dialogScope[dialogOptions.controllerAs] = ctrlInstance;
                    }
                }

                var dialogRenderData = dialogPromisesData;
                dialogRenderData.scope = dialogScope;
                dialogRenderData.windowClass = dialogOptions.windowClass;

                var body = $document.find('body').eq(0);
                body.append(dialogInstance.render(dialogRenderData));
            };

            draggableDialog.open = function (draggableDialogOptions) {
                var openedDeferred = $q.defer();

                var draggableDialogInstance = new DraggableDialogInstance();

                var draggableDialogResult = draggableDialogInstance.getLink();
                draggableDialogResult.opened = openedDeferred.promise;

                draggableDialogOptions = angular.extend({}, draggableDialogOptions);
                draggableDialogOptions.resolve = draggableDialogOptions.resolve || {};

                if (!draggableDialogOptions.template && !draggableDialogOptions.templateUrl) {
                    throw new Error('One of template or templateUrl options is required.');
                }
                var dialogPromises = new DialogPromises(getTemplatePromise(draggableDialogOptions), draggableDialogOptions.resolve);
                dialogPromises.result.then(function (dialogPromisesData) {
                    renderDialogWithData(draggableDialogInstance, draggableDialogOptions, dialogPromisesData);
                    openedDeferred.resolve(true);
                }, function (reason) {
                    openedDeferred.reject(reason);
                });

                return draggableDialogResult;
            };

            return draggableDialog;
        }]
    };
    return draggableDialogProvider;
});
'use strict';

var INTEGER_REGEXP = /^\-?\d+$/;

angular.module('adminApp').service('Util', ['$rootScope', '$q', 'EnumValuesService', '$timeout', function ($rootScope, $q, enumValuesService, $timeout) {
    var util = this;

    this.jsonTimeDifference = function (jsonDateArray1, jsonDateArray2) {
        var difference = moment(util.jsonTime(jsonDateArray1)).diff(moment(util.jsonTime(jsonDateArray2)));
        var duration = moment.duration(difference);
        var hours = Math.floor(duration.asHours());
        return (hours < 10 ? '0' + hours : hours) + moment.utc(difference).format(':mm');
    };

    this.jsonDateDaysDifference = function (jsonDateArray1, jsonDateArray2) {
        return moment(util.jsonTime(jsonDateArray1)).diff(moment(util.jsonTime(jsonDateArray2)), 'days') + 'd';
    };

    this.jsonTimeFormat = function (jsonDateArray) {
        return util.jsonTimeWithFormat(jsonDateArray, 'YYYY-MM-DD HH:mm');
    };

    this.jsonTimeWithSecondsFormat = function (jsonDateArray) {
        return util.jsonTimeWithFormat(jsonDateArray, 'YYYY-MM-DD HH:mm:ss');
    };

    this.jsonDateFormat = function (jsonDateArray) {
        return util.jsonTimeWithFormat(jsonDateArray, 'YYYY-MM-DD');
    };

    this.jsonPrettyPrint = function (jsonContent) {
        try {
            return util.jsonPrettyPrintUnsafe(jsonContent);
        } catch (e) {
            return jsonContent;
        }
    };

    this.jsonPrettyPrintUnsafe = function (jsonContent) {
        var json = JSON.parse(jsonContent);
        return util.toPrettyPrintJson(json);
    };

    this.toPrettyPrintJson = function (json) {
        return JSON.stringify(json, null, 4);
    };

    this.isDigits = function (value) {
        return INTEGER_REGEXP.test(value);
    };

    this.jsonTimeWithFormat = function (jsonDateArray, format) {
        var date = util.jsonTime(jsonDateArray);

        if (date === null) {
            return '';
        }

        if (format === undefined) {
            return date.format();
        }

        return date.format(format);
    };

    this.jsonTime = function (jsonDateArray) {
        if (!jsonDateArray) {
            return null;
        }

        if (!(jsonDateArray.length === 7 || jsonDateArray.length === 3)) {
            return null;
        }

        var dateArrayClone = jsonDateArray.slice(0);
        dateArrayClone[1]--;

        return moment(dateArrayClone);
    };

    this.dateToJsonDateOnly = function (date) {
        if (!date) {
            return null;
        }

        return this.dateTimeToJson(date).splice(0, 3);
    };

    this.dateTimeToJson = function (dateTime) {
        if (!dateTime) {
            return null;
        }

        var jsonDateTimeArray = moment(dateTime).toArray();
        jsonDateTimeArray[1]++;

        return jsonDateTimeArray;
    };

    this.transform = function (object, transforms) {
        return _.filter(_.map(transforms, function (transform) {
            var propertyValue;
            var result;
            if (_.isString(transform)) {
                propertyValue = object[transform];
                propertyValue = propertyValue || propertyValue === 0 ? propertyValue : '';
                result = { property: transform, value: '' + propertyValue };
            } else {
                var field = transform.field;
                propertyValue = object[field];
                var displayName = transform.field;

                if ('displayName' in transform) {
                    displayName = transform.displayName;
                }

                if ('transformer' in transform) {
                    var transformer = transform.transformer;
                    result = { property: displayName, value: transformer(propertyValue, object) };
                } else {
                    if (!propertyValue) {
                        propertyValue = '';
                    }
                    result = { property: displayName, value: '' + propertyValue };
                }
            }
            if (!transform.hideOnEmpty || result.value) {
                return result;
            }
        }), function (result) {
            return result;
        });
    };

    this.toStringWithCheckingNullAndUndefined = function (param) {
        var unfinishedResult = param;
        if (unfinishedResult === undefined || unfinishedResult === null) {
            unfinishedResult = '';
        }
        return '' + unfinishedResult;
    };

    this.formatTerm = function (term) {
        if (!term) {
            return '';
        }
        return term.value + ' ' + term.unit;
    };

    this.performUiBlockingRules = function (ruleList) {
        var promises = _.map(ruleList, function (rule) {
            return rule.provider().then(rule.processor);
        });
        return $rootScope.waitForPromise($q.all(promises));
    };

    this.basicClone = function (original) {
        return JSON.parse(JSON.stringify(original));
    };

    this.formatDisplayComments = function (comments) {
        if (comments.length > 200) {
            comments = comments.substring(0, 200) + '...';
        }
        return comments.replace(/(?:\r\n|\r|\n)/g, '<br />');
    };

    this.loadListDataWithEnums = function (listDataPromise, enumArray) {
        var promiseArray = [];
        promiseArray.push(listDataPromise);
        _.each(enumArray, function (enumType) {
            promiseArray.push(enumValuesService.getEnumValues(enumType));
        });
        return $q.all(promiseArray).then(function (result) {
            var dataWithEnum = { response: result[0], enums: {} };
            _.each(enumArray, function (enumType, index) {
                dataWithEnum.enums[enumType] = result[index + 1];
            });
            return dataWithEnum;
        });
    };

    this.endsWith = function (str, suffix) {
        return str.indexOf(suffix, str.length - suffix.length) !== -1;
    };

    this.joinFieldsIntoString = function (source, delimiter) {
        var result = '';
        if (source) {
            angular.forEach(source, function (value, key) {
                result += key + '=' + value + delimiter;
            });
        }
        return result;
    };

    this.randomUID = function (orderOfMagnitude) {
        if (!orderOfMagnitude) {
            orderOfMagnitude = 10000;
        }
        return Math.floor(Math.random() * orderOfMagnitude + 1);
    };

    this.utcOffsetToFullDate = function (offsetMinutes) {
        /*use utcOffset in later momentjs versions*/
        return moment().utc().zone(offsetMinutes).format("dddd, MMMM Do, H:mm:ss") + ", UTC offset = " + offsetMinutes;
    };

    this.utcOffsetToTime = function (offsetMinutes) {
        /*use utcOffset in later momentjs versions*/
        if (!offsetMinutes) {
            return 'unknown';
        }
        return moment().utc().zone(offsetMinutes).format("H:mm");
    };

    this.sortElements = function (basicQueryParams, elements) {
        var sorted = elements.sort(function (a, b) {
            var sortOption = basicQueryParams.sortOption;
            var elem1 = a[sortOption.propertyName];
            var elem2 = b[sortOption.propertyName];
            if (Array.isArray(elem1) && (elem1.length === 7 || elem1.length === 3)) {
                elem1 = util.jsonTime(elem1);
                elem2 = util.jsonTime(elem2);
            }
            return sortOption.direction === 'ASC' ? util.sort(elem1, elem2) : util.sort(elem2, elem1);
        });
        return $q.when(sorted);
    };

    this.sort = function (a, b) {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    };

    util.promiseWhile = function (condition, body, retryCount, retryDelay, firstRetryDelay) {
        var done = $q.defer();
        var remainingRetryCount = retryCount;
        var firstRetryDone = false;

        var processResultsAndScheduleNextLoop = function processResultsAndScheduleNextLoop(noDelay) {
            if (!condition()) {
                done.resolve();
                return;
            }

            remainingRetryCount--;
            if (remainingRetryCount < 0) {
                done.reject('retry ended');
                return;
            }

            var delay;
            if (!!noDelay) {
                delay = 0;
            } else if (!firstRetryDone && !!firstRetryDelay) {
                delay = firstRetryDelay;
                firstRetryDone = true;
            } else {
                delay = retryDelay;
            }

            $timeout(loop, delay);
        };

        var loop = function loop() {
            // Use `when`, in case `body` does not return a promise.
            // When it completes loop again otherwise, if it fails, reject the
            // done promise

            $q.when(body(), processResultsAndScheduleNextLoop, done.reject);
        };

        // Start running the loop in the next tick so that this function is
        // completely async. It would be unexpected if `body` was called
        // synchronously the first time.
        $timeout(function firstLoop() {
            processResultsAndScheduleNextLoop(true);
        }, 0);

        // The promise
        return done.promise;
    };
}]);
'use strict';

angular.module('adminApp').service('EntityTransforms', ['Util', '$sce', 'ProductConfigurationService', function (Util) {
    var self = this;

    function Transform(transforms) {
        var self = this;
        self.transforms = transforms;

        self.transform = function (entity) {
            return Util.transform(entity, self.transforms);
        };
    }

    var isOpenLoanWithSchedule = function isOpenLoanWithSchedule(loan) {
        return loan.scheduleItems.length > 1 && loan.status === "OPEN";
    };

    var showLoanOpenAmount = function showLoanOpenAmount(openAmount, loan) {
        if (!isOpenLoanWithSchedule(loan)) {
            return openAmount;
        }
        var scheduleItemsTotalOpenAmount = 0;
        angular.forEach(loan.scheduleItems, function (scheduleItem) {
            scheduleItemsTotalOpenAmount += scheduleItem.openAmount;
        });
        return openAmount + " / unpaid schedule: " + scheduleItemsTotalOpenAmount.toFixed(2);
    };

    var showLoanAndScheduleAmounts = function showLoanAndScheduleAmounts(scheduleProperty) {
        return function (balanceAmount, loan) {
            if (!isOpenLoanWithSchedule(loan)) {
                return balanceAmount;
            }
            var total = 0;
            var now = new Date();
            angular.forEach(loan.scheduleItems, function (scheduleItem) {
                if (Util.jsonTime(scheduleItem.startDate) <= now) {
                    total += scheduleItem[scheduleProperty];
                }
            });
            if (total > 0) {
                return balanceAmount + " / by schedule: " + total.toFixed(2);
            } else {
                return balanceAmount;
            }
        };
    };

    var showLoanExtensions = function showLoanExtensions(extensions) {
        var output = '';
        angular.forEach(extensions, function (extension) {
            output += Util.formatTerm(extension.term) + ': ' + extension.fee.toFixed(2) + '<br/>';
        });
        return output;
    };

    var showLoanRefinances = function showLoanRefinances(refinances) {
        var output = '';
        angular.forEach(refinances, function (ref) {
            output += Util.formatTerm(ref.term) + ': ' + ref.amount.toFixed(2) + '<br/>';
        });
        return output;
    };

    var loanLenderName = function loanLenderName(loanLender) {
        return loanLender.name;
    };

    var clientLink = function clientLink(client) {
        var clientFullName = _.compact([client.firstName, client.middleName, client.lastName, client.secondLastName]).join(' ');
        return '<a href="#/client/' + client.id + '"> ' + clientFullName + '</a>';
    };

    var loanApplicationClient = function loanApplicationClient(dummy, loanApplication) {
        var title = loanApplication.clientFullName;
        if (!title) {
            title = loanApplication.clientId;
        }
        return '<a href="#/client/' + loanApplication.clientId + '"> ' + title + '</a>';
    };

    var limitReferer = function limitReferer(dummy, loanApplication) {
        var referer = loanApplication.referer;
        if (!referer) {
            referer = '';
        }
        return '<occ-long-field limit-to="30">' + $sce.getTrustedHtml(referer) + '</occ-long-field>';
    };

    var warningStatus = function warningStatus(status) {
        var result = status;
        if (status === 'CLOSED') {
            result += ' <span class="text-danger">(<i class="fa fa-info-circle"></i> CLOSED)</span>';
        }
        return result;
    };

    var warningDateTime = function warningDateTime(dateTime) {
        var result = Util.jsonTimeFormat(dateTime);
        var daysSinceNow = moment().diff(Util.jsonTime(dateTime), 'days');
        if (daysSinceNow >= 1) {
            result += ' <span class="text-danger">(<i class="fa fa-info-circle"></i> ' + daysSinceNow + ' days old)</span>';
        }

        return result;
    };

    var dateTimeAndDays = function dateTimeAndDays(dateTime) {
        var result = Util.jsonTimeFormat(dateTime);
        var daysSinceNow = moment().diff(Util.jsonTime(dateTime), 'days');
        if (daysSinceNow >= 1) {
            result += '<br/> (' + daysSinceNow + ' days old)';
        }

        return result;
    };

    var asTransformMap = function asTransformMap(transformDefinitions) {
        return _.object(_.map(transformDefinitions, function (transformDefinition) {
            if (_.isString(transformDefinition)) {
                return [transformDefinition, transformDefinition];
            } else {
                return [transformDefinition.field, transformDefinition];
            }
        }));
    };

    var transformAddressLine = function transformAddressLine(addressLine) {
        return addressLine;
    };

    var LOAN_TRANSFORMS = asTransformMap(['id', 'number', 'agreementAmount', 'agreementType', 'overdueDays', { field: 'agreementTerm', transformer: Util.formatTerm, displayName: 'term' }, { field: 'dueDate', transformer: Util.jsonDateFormat }, { field: 'closedDate', transformer: Util.jsonDateFormat }, { field: 'mainStartDate', transformer: Util.jsonDateFormat }, { field: 'currentAgreementStartDate', transformer: Util.jsonDateFormat, displayName: 'agreementStartDate' }, { field: 'openAmount', transformer: showLoanOpenAmount }, { field: 'nextRepaymentDate', transformer: Util.jsonDateFormat }, { field: 'nextRepaymentAmount', displayName: 'nextRepayment', hideOnEmpty: true }, { field: 'principalBalance', transformer: showLoanAndScheduleAmounts('openPrincipal') }, { field: 'interestBalance', transformer: showLoanAndScheduleAmounts('openInterest') }, {
        field: 'loanCommissionBalance',
        transformer: showLoanAndScheduleAmounts('openLoanCommission'),
        displayName: 'loan commission balance'
    }, { field: 'loanCommissionBalance', transformer: showLoanAndScheduleAmounts('openLoanCommission') }, { field: 'preparationFeeBalance', transformer: showLoanAndScheduleAmounts('preparationFeeBalance') }, { field: 'loanAnnualCommissionBalance', transformer: showLoanAndScheduleAmounts('openLoanAnnualCommission') }, { field: 'penaltyBalance', transformer: showLoanAndScheduleAmounts('openPenalty') }, { field: 'commissionBalance', transformer: showLoanAndScheduleAmounts('openScheduleCommission') }, { field: 'loanFeesBalance', transformer: showLoanAndScheduleAmounts('openFees') }, { field: 'extensionFees', transformer: showLoanExtensions }, { field: 'refinanceFees', transformer: showLoanRefinances }, { field: 'refinanceFeesWithDiscount', transformer: showLoanRefinances }, { field: 'loanLender', transformer: loanLenderName }, { field: 'client', transformer: clientLink }, 'status', 'statusDetail', 'agreementCount', 'currentDebtStep', 'maxDelayDays', { field: 'created', transformer: Util.jsonTimeFormat }, { field: 'firstOpenItemRepaymentAmount', displayName: 'scheduleItemAmount', hideOnEmpty: true }, { field: 'delayAmount', hideOnEmpty: true }, { field: 'earlyFullRepaymentAmount', displayName: "earlyFullRepayment", hideOnEmpty: true }, 'productType']);

    var LOAN_CURRENT_AGREEMENT_TRANSFORMS = asTransformMap(['agreementType', 'interestRatePerYear', 'penaltyRatePerDay', 'maxPenaltyCoeff', { field: 'annualPercentageRate', displayName: 'annual percentage rate' }, { field: 'penaltyGracePeriodDays', displayName: 'penalty grace period days' }, { field: 'agreementAmount', displayName: 'amount' }, { field: 'currentAgreementStartDate', displayName: 'startDate', transformer: Util.jsonDateFormat }, { field: 'currentAgreementSignDate', displayName: 'signDate', transformer: Util.jsonDateFormat }, { field: 'agreementTerm', displayName: 'term', transformer: Util.formatTerm }, { field: 'agreementExtensionTerm', displayName: 'extensionTerm', transformer: Util.formatTerm }, { field: 'agreementDiscountPercent', displayName: 'discount percent' }, { field: 'agreementRefinanceDiscountPercent', displayName: 'refinance discount percent' }, { field: 'created', transformer: Util.jsonTimeFormat }]);

    var CLIENT_TRANSFORMS = asTransformMap(['id', 'number', 'status', 'blocked', 'identifiedBy', 'firstName', 'middleName', 'lastName', 'secondLastName', 'personalId', 'language', 'mobilePhone', 'landLinePhone', 'email', 'age', 'gender', 'placeOfBirth', 'loanLenders', { field: 'dateOfBirth', transformer: Util.jsonDateFormat }, 'creditLimit', 'availableCreditLimit', 'discountPercent', 'surplusAmount', 'bankAccountNumber', 'paymentChannelType', 'acceptNews', 'loansCount', 'canApplyForLoan', { field: 'canClientApplyForLoanRejectReason', displayName: 'RejectReason' }, 'canApplyForAdditionalAmount', 'canClientApplyForRefinance', { field: 'actualPostAddressSingleLine', transformer: transformAddressLine, displayName: 'actualAddress' }, { field: 'declaredPostAddressSingleLine', transformer: transformAddressLine, displayName: 'declaredAddress' }, 'finishedDetailsStep', { field: 'created', transformer: Util.jsonTimeFormat }]);

    var ADDITIONAL_AMOUNT_OFFER_SCRIPT_TRANSFORMS = asTransformMap([{ field: 'principal', displayName: 'principal' }, { field: 'totalAmount', displayName: 'totalAmount' }, { field: 'totalLoanCommission', displayName: 'loanCommission' }, { field: 'totalInterest', displayName: 'interest' }]);

    self.getTransform = function (allTransformDefinitionMap, selectedFields) {
        return new Transform(_.map(selectedFields, function (selectedField) {
            return allTransformDefinitionMap[selectedField];
        }));
    };

    self.getLoanTransform = function (fields) {
        return self.getTransform(LOAN_TRANSFORMS, fields);
    };

    self.getClientTransform = function (fields) {
        return self.getTransform(CLIENT_TRANSFORMS, fields);
    };

    self.getAdditionalAmountOfferScriptTransform = function (fields) {
        return self.getTransform(ADDITIONAL_AMOUNT_OFFER_SCRIPT_TRANSFORMS, fields);
    };
}]);
'use strict';

angular.module('adminApp').service('LoanService', ['$rootScope', '$http', 'Util', function ($rootScope, $http) {
    var WS_URI = '/ws/api';

    this.getAdditionalAmountOffer = function (id, amount) {
        return $http.post(WS_URI + '/external-cc/loan/' + id + '/additional-amount-offer?amount=' + amount);
    };

    this.applyForAdditionalAmount = function (applyForAdditionalAmountCommand) {
        return $http.post(WS_URI + '/external-cc/loan/apply-additional-amount', applyForAdditionalAmountCommand);
    };

    this.applyForRefinanceApplication = function (applyForRefinanceCommand) {
        return $http.post(WS_URI + '/external-cc/loan/apply-for-refinance', applyForRefinanceCommand);
    };
}]);
'use strict';

angular.module('adminApp').service('PhoneCallApplicationService', ['$http', function ($http) {
    var WS_URI = '/ws/api';

    this.getClientByPhoneAndPersonalCode = function (mobilePhone, personalCode) {
        return $http.get(WS_URI + '/external-cc/client/search?phone=' + mobilePhone + '&personal_code=' + personalCode);
    };

    this.getLoanOffer = function (clientId, amount, termValue, termUnit) {
        var term = termValue ? { value: termValue, unit: termUnit || 'DAYS' } : null;
        var offerRequest = { 'amount': amount, 'term': term };
        return $http.post(WS_URI + '/external-cc/client/' + clientId + '/offer', offerRequest);
    };

    this.apply = function (clientId, amount, termValue, termUnit, askedAmount, askedTermValue, autoConfirm) {
        var askedTerm = askedTermValue ? { value: askedTermValue, unit: termUnit || 'DAYS' } : null;
        var term = termValue ? { value: termValue, unit: termUnit || 'DAYS' } : null;
        var applyCmd = {
            'clientId': clientId,
            'amount': amount,
            'term': term,
            'askedAmount': askedAmount,
            'askedTerm': askedTerm,
            'autoConfirm': autoConfirm
        };
        return $http.post(WS_URI + '/external-cc/loan/apply-via-phone-call', applyCmd);
    };
}]);
'use strict';

(function (angular) {
    'use strict';

    angular.module('adminApp').service('CompanyNameService', ['ProductConfigurationService', '$q', function (productConfiguration, $q) {
        var service = this;

        service.getCompanyName = function () {
            var deferred = $q.defer();
            if (!service.companyName) {
                service.companyName = productConfiguration.getDefaultProductCompanyName();
                deferred.resolve(service.companyName);
            }
            return deferred.promise;
        };
    }]);
})(angular);
'use strict';

angular.module('adminApp').service('EnumValuesService', ['$http', '$q', function ($http, $q) {
    var WS_URI = '/ws/api';

    var cachedEnumValuesValues = {};

    this.getEnumValues = function (enumName) {
        if (!cachedEnumValuesValues[enumName]) {
            return $http.get(WS_URI + '/enum-values/' + enumName).then(function (result) {
                cachedEnumValuesValues[enumName] = result.data;
                return result.data;
            });
        } else {
            return $q.when(cachedEnumValuesValues[enumName]);
        }
    };
}]);
'use strict';

angular.module('adminApp').service('PlClientService', ['$rootScope', '$http', function ($rootScope, $http) {
    this.addConsent = function (clientId, consentName) {
        return $http.post('/ws/api/external-cc/client/' + clientId + '/add-consent?consentName=' + consentName);
    };

    this.acceptNews = function (clientId) {
        return $http.post('/ws/api/external-cc/client/' + clientId + '/accept-news');
    };
}]);
'use strict';

angular.module('adminApp').service('User', ['$rootScope', '$http', function ($rootScope, $http) {
    var _user = null;
    var user = {};

    user.isAuthenticated = function () {
        return _user !== null;
    };

    user.authenticated = function (userName, permissions) {
        _user = {
            userName: userName,
            permissions: permissions
        };
        $rootScope.$broadcast('user-authenticated', _user);
    };

    user.getUser = function () {
        return angular.copy(_user);
    };

    user.loadUser = function () {
        $http.get('/ws/api/external-cc/loggedin/username').then(function (response) {
            var username = response.data;
            user.loadPermissions().then(function (permissions) {
                user.authenticated(username, permissions);
            });
        });
    };

    user.loadPermissions = function () {
        return $http.get('/ws/api/external-cc/loggedin/permissions').then(function (response) {
            var permissions = response.data;
            return permissions;
        });
    };

    user.hasPermission = function (permissions) {
        if (!_user) {
            return false;
        }

        if (_.contains(_user.permissions, 'all.all')) {
            return true;
        }

        return !!_.find(permissions.split(","), function (permission) {
            return _.contains(_user.permissions, permission.trim());
        });
    };

    user.getLogoutPromise = function () {
        return $http.get('/logout').then(function () /* response */{
            _user = null;
            $rootScope.$broadcast('user.logoutSuccess');
        });
    };

    return user;
}]);
'use strict';

angular.module('adminApp').service('ProductConfigurationService', ['$rootScope', function ($rootScope) {

    this.getDefaultProductCompanyName = function () {
        return $rootScope.POLISH_NETCREDIT;
    };
}]);
'use strict';

angular.module('adminApp').service('CacheModelViewStateManager', [function () {

    function CacheModelViewStateManager() {
        this.customPostSuspend = null;

        this.registerCustomPostSuspend = function (customPostSuspendFunction) {
            this.customPostSuspend = customPostSuspendFunction;
            return this;
        };

        this.extend = function (childDefinition) {
            return _.extend(_.clone(this), childDefinition);
        };

        this.restoreViewState = function ($stateParams, savedState) {
            return savedState;
        };

        this.suspendViewState = function (scope) {
            var viewState = { model: scope.model };
            if (this.customPostSuspend) {
                this.customPostSuspend(viewState, scope);
            }
            return viewState;
        };
    }

    this.create = function () {
        return new CacheModelViewStateManager();
    };
}]);
'use strict';

angular.module('adminApp').service('CacheListModelViewStateManager', ['defaultQueryParams', function (defaultQueryParams) {

    function CacheListModelViewStateManager(serverTableSerializationManagerVariableName) {
        var manager = this;

        manager.withCreateQueryParams = function (basicQueryParams) {
            manager.createQueryParams = basicQueryParams;
            return manager;
        };

        manager.extend = function (childDefinition) {
            return _.extend(_.clone(manager), childDefinition);
        };

        manager.createViewState = function ($stateParams, stateName) {
            return this.provideViewStateData($stateParams, stateName).then(function (newViewState) {
                if (_.isUndefined(newViewState.model.serverTableParams)) {
                    var queryParams = _.isUndefined(manager.createQueryParams) ? defaultQueryParams : manager.createQueryParams;
                    newViewState.model.serverTableParams = queryParams;
                }
                return newViewState;
            });
        };

        manager.restoreViewState = function ($stateParams, savedState) {
            return savedState;
        };

        manager.suspendViewState = function (scope) {
            var viewState = { model: scope.model };
            viewState.model.serverTableParams = scope[serverTableSerializationManagerVariableName].serialize();
            return viewState;
        };
    }

    this.create = function (serverTableSerializationManagerVariableName) {
        return new CacheListModelViewStateManager(serverTableSerializationManagerVariableName);
    };
}]);
'use strict';

angular.module('adminApp').controller('HomeCtrl', [function () {}]);
'use strict';

angular.module('adminApp').controller('RootCtrl', ['$rootScope', '$scope', '$window', 'Heartbeat', '$timeout', 'ErrorDialog', 'ViewHistory', 'exceptionListenerLink', function ($rootScope, $scope, $window, Heartbeat, $timeout, errorDialog, viewHistory, exceptionListenerLink) {

    $rootScope.displayedCompanyName = "Netcredit";
    $rootScope.loadingMessage = "Netcredit";

    if (window.location.href.indexOf('adcredit') >= 0) {
        $rootScope.displayedCompanyName = 'AdCredit';
        $rootScope.loadingMessage = "AdCredit";
    }

    if (window.location.href.indexOf('credityou') >= 0) {
        $rootScope.displayedCompanyName = 'CreditYou';
        $rootScope.loadingMessage = "CreditYou";
    }

    if (window.location.href.indexOf('incredit') >= 0) {
        $rootScope.displayedCompanyName = 'Incredit';
        $rootScope.loadingMessage = "Incredit";
    }

    if (window.location.href.indexOf('credit123') >= 0) {
        $rootScope.displayedCompanyName = 'Credit123';
        $rootScope.loadingMessage = "Credit123";
    }

    if (window.location.href.indexOf('pplfinance') >= 0) {
        $rootScope.displayedCompanyName = 'PPLFinance';
        $rootScope.loadingMessage = "PPLFinance";
    }

    $rootScope.$on('$stateChangeStart', function () {
        $rootScope.showOverlay();
    });

    $rootScope.$on('$stateChangeSuccess', function () {
        $rootScope.hideOverlay();
    });

    $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
        $rootScope.defaultErrorHandler(error);
    });

    $scope.$app = {};

    $rootScope.overlayEnabled = false;

    $rootScope.showOverlay = function () {
        $rootScope.overlayEnabled = true;
    };

    $rootScope.hideOverlay = function () {
        $rootScope.overlayEnabled = false;
    };

    $scope.reloadPage = function () {
        $window.location.reload(true);
    };

    $scope.$on('$destroy', function () {
        Heartbeat.stopPolling();
    });

    $rootScope.alertIdSequence = 0;

    $rootScope.alerts = [];

    $rootScope.closeAlert = function (index) {
        $rootScope.alerts.splice(index, 1);
    };

    $rootScope.notifySuccess = function (message) {
        $timeout(function () {
            var alertId = $rootScope.alertIdSequence++;
            $rootScope.alerts.push({ type: 'success', msg: message, id: alertId });
            return alertId;
        }, 500).then(function (alertId) {
            $timeout(function () {
                var alertIndex = _.chain($rootScope.alerts).pluck('id').indexOf(alertId).value();
                $rootScope.alerts.splice(alertIndex, 1);
            }, 3000);
        });
    };

    $rootScope.showServerError = function (errorResponse) {
        return errorDialog.showServerError(errorResponse).result;
    };

    $rootScope.defaultErrorHandler = function (errorResponse) {
        $rootScope.hideOverlay();
        return $rootScope.showServerError(errorResponse);
    };

    $rootScope.finishViewWithPromise = function (promise, successMessage) {
        $rootScope.showOverlay(); //Overlay should be shown before promise, but as JS is singlethreaded then this is acceptable
        promise.then(function () /*successResponse */{
            $rootScope.hideOverlay();
            $rootScope.notifySuccess(successMessage);
            viewHistory.closeCurrentHistoryEntry();
        }, $rootScope.defaultErrorHandler);
    };

    $rootScope.finishDialogWithPromise = function (promise, dialogInstance, successMessage) {
        $rootScope.showOverlay();
        promise.then(function () {
            $rootScope.hideOverlay();
            $rootScope.notifySuccess(successMessage);
            dialogInstance.close('success');
        }, $rootScope.defaultErrorHandler);
    };

    $rootScope.waitForPromise = function (promise) {
        $rootScope.showOverlay(); //Overlay should be shown before promise, but as JS is singlethreaded then this is acceptable
        return promise.then(function (successResponse) {
            $rootScope.hideOverlay();
            return successResponse;
        }, $rootScope.defaultErrorHandler);
    };

    exceptionListenerLink.registerListener({ onException: $rootScope.defaultErrorHandler });

    Heartbeat.startPolling();
}]);
'use strict';

angular.module('adminApp').controller('WelcomeCtrl', ['$rootScope', '$scope', '$state', '$timeout', 'User', 'session', function ($rootScope, $scope, $state, $timeout, User, session) {
    $scope.$welcome = {};

    function goToAdmin() {
        var initialState = $rootScope.$app.initialState;
        if (initialState) {
            delete $rootScope.$app.initialState;
            $state.transitionTo(initialState.state.name, initialState.params);
        } else {
            $state.transitionTo('app.admin.home');
        }
    }

    $scope.$on('user-authenticated', function () {
        goToAdmin();
    });

    if (session.loggedin === 'true') {
        User.loadUser();
    }
}]);
'use strict';

angular.module('adminApp').controller('LoginCtrl', ['$rootScope', '$scope', '$http', 'User', 'Errors', function ($rootScope, $scope, $http, User, Errors) {

    $scope.errors = {};
    $http.post('/ws/public/init-session');

    $scope.login = function () {

        //Workaround fixing problem with autofilled values (LastPass etc)
        $scope.user.name = $('#signInLogin').val();
        $scope.user.password = $('#signInPassword').val();

        $scope.errors = {};
        $scope.showOverlay();
        $http.post('/ws/api/external-cc/login', $scope.user).success(function (response) {
            $scope.hideOverlay();
            User.authenticated($scope.user.name, response);
            Errors.clearAllErrors();
        }).error(function (data, status) {
            $scope.hideOverlay();
            if (status === 401) {
                $scope.errors.error = 'Failed to login';
            } else if (status === 403) {
                $scope.errors.error = 'Failed to login, bad request';
            } else {
                $scope.errors.error = 'Failed to login, unexpected error';
            }
        });
    };
}]);
'use strict';

angular.module('adminApp').controller('LogoutCtrl', ['$state', function ($state) {
    $state.go('app.welcome.login');
}]);
'use strict';

(function () {
    'use strict';

    angular.module('adminApp').controller('AdminCtrl', ['$scope', '$state', 'User', 'ProductConfigurationService', 'CompanyNameService', AdminCtrl]);

    function AdminCtrl($scope, $state, User, productConfiguration, companyNameService) {
        $scope.$adminApp = {};

        $scope.logout = function () {
            $state.go('app.welcome.logout');
        };

        $scope.userName = User.getUser().userName;

        $scope.init = function () {
            $scope.companyName = productConfiguration.getDefaultProductCompanyName();
        };

        angular.extend($scope, companyNameService);
        $scope.getCompanyName();
        $scope.init();
    }
})();
'use strict';

angular.module('adminApp').controller('ViewHistoryCtrl', ['$scope', '$state', 'ViewHistory', '$rootScope', function ($scope, $state, ViewHistory, $rootScope) {
    $scope.historyViewEntries = ViewHistory.getAsViewHistoryEntries();

    $rootScope.$on('viewHistory.activated', function (event, uid) {
        activate(uid);
    });

    $rootScope.$on('viewHistory.removed', function (event, uid) {
        var removableIndex = findIndexByUID(uid);
        removeHistoryEntry(removableIndex);
    });

    $rootScope.$on('viewHistory.added', function (event, historyEntry) {
        var currentIndex = findActiveIndex();
        insertHistoryEntry(currentIndex + 1, historyEntry);
    });

    $rootScope.$on('viewHistory.all-deactivated', function () {
        _.each($scope.historyViewEntries, function (historyEntry) {
            historyEntry.active = false;
        });
    });

    var removeHistoryEntry = function removeHistoryEntry(removableIndex) {
        $scope.historyViewEntries.splice(removableIndex, 1);
    };

    var insertHistoryEntry = function insertHistoryEntry(index, historyEntry) {
        $scope.historyViewEntries.splice(index, 0, historyEntry);
    };

    var findIndexByUID = function findIndexByUID(uid) {
        return _.chain($scope.historyViewEntries).pluck('uid').indexOf(uid).value();
    };

    var findActiveIndex = function findActiveIndex() {
        return _.chain($scope.historyViewEntries).pluck('active').indexOf(true).value();
    };

    var activate = function activate(uid) {
        _.each($scope.historyViewEntries, function (historyEntry) {
            historyEntry.active = historyEntry.uid === uid;
        });
    };

    $scope.activateHistoryEntry = function (historyEntry) {
        ViewHistory.changeState(historyEntry.uid);
    };

    $scope.closeHistoryEntry = function (historyEntry) {
        ViewHistory.closeHistoryEntry(historyEntry.uid);
    };

    $scope.closeAllEntries = function () {
        ViewHistory.closeAllEntries();
    };

    $scope.closeNonActiveEntries = function () {
        ViewHistory.closeNonActiveEntries();
    };
}]);
'use strict';

angular.module('adminApp').factory('PhoneCallApplicationCtrlViewStateManager', ['PhoneCallApplicationService', 'CacheModelViewStateManager', 'EntityTransforms', '$q', '$http', function (phoneCallApplicationService, CacheModelViewStateManager, EntityTransforms, $q, $http) {
    return CacheModelViewStateManager.create().extend({
        createViewState: function createViewState() {
            return $http.get('/ws/public/calculator-params').then(function (result) {
                return { model: { client: null, calcParams: result.data } };
            });
        },
        CLIENT_TRANSFORMS: EntityTransforms.getClientTransform(['firstName', 'lastName', 'personalId', 'age', 'gender', 'creditLimit', 'availableCreditLimit', 'actualPostAddressSingleLine', 'canApplyForLoan', 'canApplyForAdditionalAmount', 'canClientApplyForRefinance'])
    });
}]).controller('PhoneCallApplicationCtrl', ['$scope', '$rootScope', '$state', '$stateParams', 'ViewState', 'PhoneCallApplicationService', 'PlClientService', 'ConfirmDialog', 'Util', 'PhoneCallApplicationCtrlViewStateManager', 'ClientService', 'ViewHistory', 'ExternalCompanyDetails', function ($scope, $rootScope, $state, $stateParams, viewState, phoneCallApplicationService, plClientService, confirmDialog, Util, PhoneCallApplicationCtrlViewStateManager, ClientService, ViewHistory, ExternalCompanyDetails) {

    $scope.affiliateConfigurationName = ExternalCompanyDetails.data.company;
    $scope.affiliatePartnerName = ExternalCompanyDetails.data.email;

    var model = viewState.model;

    $scope.mobilePhone = $stateParams.mobilePhone;
    $scope.autoConfirm = $stateParams.autoConfirm;
    $scope.personalCode = null;
    $scope.successMessage = null;
    $scope.application = {};
    $scope.showNewsConsent = false;
    initDefaultValues();

    $scope.payoutTypes = ['NORMAL', 'EXPRESS'];
    $scope.application.payout = { type: $scope.payoutTypes[0] };

    $scope.searchClientByPhone = function () {
        if ($scope.mobilePhone !== null && $scope.personalCode !== null) {
            $scope.offer = null;
            phoneCallApplicationService.getClientByPhoneAndPersonalCode($scope.mobilePhone, $scope.personalCode).then(function (result) {
                $scope.successMessage = null;
                $scope.showWarningMessage = false;
                $scope.showNewClientRegistrationUrl = false;
                if (result.data.found) {
                    processResponse(result.data.client, model.calcParams);
                } else {
                    processEmptyResponse(result.data);
                }
            }, function () {
                processEmptyResponse("Error occurred, can't perform search. Please contact development team");
            });
        }
    };

    $scope.show = function (message, key) {
        if (key.substring(0, 3) === "can") {
            return message === "" ? "No" : "Yes";
        }

        return message;
    };

    $scope.acceptNews = function () {
        plClientService.acceptNews($scope.clientId).then(function (res) {
            $scope.showNewsConsent = res.acceptNews;
        });
    };

    $scope.applyForLoan = function () {
        if ($scope.canApplyForAdditionalAmount && Number($scope.application.amount) > Number($scope.availableCreditLimit)) {
            $scope.showServerError('Error: Amount exceeds available credit limit!');
            $scope.offer = null;
            return;
        }
        $scope.showOverlay();
        phoneCallApplicationService.getLoanOffer($scope.clientId, $scope.application.amount, $scope.application.term.value, $scope.application.term.unit).then(function (response) {
            $scope.hideOverlay();
            $scope.offer = response.data;
        }, function (response) {
            $scope.hideOverlay();
            var errorMessage = response.data.message;
            $scope.showServerError('Error: ' + errorMessage);
            $scope.offer = null;
        });
    };

    var agreeConsents = function agreeConsents() {
        return plClientService.addConsent($scope.clientId, "agreePep");
    };

    var createLoanApplication = function createLoanApplication(autoConfirm) {

        if (allMandatoryConsentsChecked() === false) {
            $scope.showServerError('Mandatory consents needs to be checked!');
            return;
        }

        $scope.showOverlay();
        agreeConsents().then(function () {
            phoneCallApplicationService.apply($scope.clientId, $scope.offer.newPrincipal, $scope.offer.term.value, $scope.offer.term.unit, $scope.application.amount, $scope.application.term.value, autoConfirm).then(SuccessHandler, ErrorHandler);
        });
    };

    $scope.confirmLoanApplication = function () {
        createLoanApplication(false);
    };

    $scope.issueLoan = function () {
        createLoanApplication(true);
    };

    $scope.isAutoConfirm = function () {
        return $stateParams.autoConfirm === "true";
    };

    $scope.confirmLoanApplicationNcGe = function () {

        if (allMandatoryConsentsChecked() === false) {
            $scope.showServerError('Mandatory consents needs to be checked!');
            return;
        }

        $scope.showOverlay();
        agreeConsents().then(function () {
            var isExpressPayout = $scope.application.payout.type === $scope.payoutTypes[1];
            phoneCallApplicationService.applyNcGe($scope.clientId, $scope.offer.newPrincipal, $scope.offer.term.value, $scope.offer.term.unit, $scope.application.amount, $scope.application.term.value, isExpressPayout).then(SuccessHandler, ErrorHandler);
        });
    };

    function SuccessHandler(response) {
        $scope.hideOverlay();
        var application = response.data;
        if (application.resolution === 'APPROVED') {
            $scope.notifySuccess('Application: ' + application.id + ' approved!');
        } else if (application.rejectReason === 'TERMS_CHANGED') {
            $scope.showServerError(['Application:', application.id, 'not approved! Terms changed to: amount:', application.amount + ',', 'term', application.term.value, application.term.unit + '!', 'Press "Apply" to proceed to confirmation!'].join(' '));
            $scope.application.amount = application.amount;
            $scope.application.term.value = application.term.value;
            $scope.application.term.unit = application.term.unit || 'DAYS';
            $scope.offer = null;
        } else if (!application.resolution && application.confirmed === false) {
            $scope.successMessage = 'Application: registered! Client must send approval SMS';
            initDefaultValues();
        } else {
            $scope.showServerError(['Application:', application.id, 'not approved! Resolution:', application.resolution + ',', 'reject reason:', application.rejectReason].join(' '));
        }
    }

    function ErrorHandler(response) {
        $scope.hideOverlay();
        var errorMessage = response.data.message;
        $scope.showServerError('Error: ' + errorMessage);
    }

    function processEmptyResponse(data) {
        initDefaultValues();
        $scope.showWarningMessage = true;
        $scope.warningMessage = data.message;
        $scope.showNewClientRegistrationUrl = data.showRegistrationUrl;
        $scope.registrationUrl = data.registrationUrl + '?configurationName=' + $scope.affiliateConfigurationName + '&partnerName=' + $scope.affiliatePartnerName;
    }

    function initDefaultValues() {
        $scope.offer = null;
        $scope.client = null;
        $scope.loanId = null;
        $scope.application = {};
        $scope.canApplyForLoan = false;
        $scope.canApplyForAdditionalAmount = false;
        $scope.canClientApplyForRefinance = false;
        $scope.clientConsents = {
            agreeWithAll: false,
            agreeWithTerms: false,
            agreeWithBik: false,
            agreeWithBig: false,
            agreeEUCB: false,
            agreePep: false,
            agreeReceiveNewsAll: false,
            agreeReceiveNewsSms: false,
            agreeReceiveNewsPhone: false,
            agreeReceiveNewsEmail: false,
            agreeSpvBroker: false,
            agreeIncredit: false
        };
    }

    $scope.newsAllCheckboxClicked = function () {
        _.each(['agreeReceiveNewsSms', 'agreeReceiveNewsPhone', 'agreeReceiveNewsEmail'], function (key) {
            $scope.clientConsents[key] = $scope.clientConsents.agreeReceiveNewsAll;
        });
    };

    $scope.isAnyNewsConsentChecked = function () {
        return $scope.clientConsents.agreeReceiveNewsSms || $scope.clientConsents.agreeReceiveNewsPhone || $scope.clientConsents.agreeReceiveNewsEmail;
    };

    $scope.agreeWithAll = function () {
        var checked = $scope.clientConsents.agreeWithAll;
        $scope.clientConsents.agreeWithTerms = checked;
        $scope.clientConsents.agreeWithBik = checked;
        $scope.clientConsents.agreeWithBig = checked;
        $scope.clientConsents.agreeEUCB = checked;
        $scope.clientConsents.agreePep = checked;
        $scope.clientConsents.agreeSpvBroker = checked;
        $scope.clientConsents.agreeIncredit = checked;
    };

    function allMandatoryConsentsChecked() {
        return $scope.clientConsents.agreeWithTerms && $scope.clientConsents.agreeWithBik && $scope.clientConsents.agreeWithBig && $scope.clientConsents.agreeEUCB && $scope.clientConsents.agreePep && $scope.clientConsents.agreeIncredit;
    }

    function processResponse(client, calcParams) {

        initDefaultValues();

        $scope.client = PhoneCallApplicationCtrlViewStateManager.CLIENT_TRANSFORMS.transform(client);
        $scope.canApplyForLoan = client.canApplyForLoan;
        $scope.canApplyForAdditionalAmount = client.canApplyForAdditionalAmount;
        $scope.canClientApplyForRefinance = client.canClientApplyForRefinance;
        $scope.application.term = $scope.application.term || calcParams.defaultTerm;

        $scope.showNewsConsent = !client.acceptNews;

        if ($scope.canClientApplyForRefinance) {
            ClientService.getClientLastLoan(client.id).then(function (response) {
                $scope.loanId = response.data.id;
            });
        }

        if ($scope.canApplyForLoan) {
            $scope.application.amount = client.creditLimit;
        } else if ($scope.canApplyForAdditionalAmount) {
            $scope.application.amount = client.availableCreditLimit;
            $scope.application.term.value = null;
        }
        $scope.availableCreditLimit = client.availableCreditLimit;
        $scope.clientId = client.id;
    }
}]);
'use strict';

angular.module('adminApp').factory('PhoneCallAdditionalAmountApplicationCtrlViewStateManager', ['LoanService', 'ClientService', 'CacheModelViewStateManager', 'EntityTransforms', '$q', '$http', function (loanService, clientService, CacheModelViewStateManager, EntityTransforms, $q, $http) {
    return CacheModelViewStateManager.create().extend({
        createViewState: function createViewState($stateParams) {
            return $q.all([clientService.getClientLastLoan($stateParams.clientId), clientService.getClient($stateParams.clientId)]).then(function (result) {
                return { model: { loan: result[0].data, client: result[1].data } };
            });
        },
        LOAN_TRANSFORM: EntityTransforms.getLoanTransform(['status', 'principalBalance', 'interestBalance', 'loanCommissionBalance', 'loanAnnualCommissionBalance', 'dueDate']),
        ADDITIONAL_AMOUNT_OFFER_TRANSFORM: EntityTransforms.getAdditionalAmountOfferScriptTransform(['principal', 'totalAmount', 'totalLoanCommission', 'totalInterest'])
        // CLIENT_TRANSFORMS: EntityTransforms.getClientTransform(['acceptNews'])

    });
}]).controller('PhoneCallAdditionalAmountApplicationCtrl', ['$scope', '$state', '$stateParams', 'ViewState', 'PhoneCallApplicationService', 'ConfirmDialog', 'Util', 'PhoneCallAdditionalAmountApplicationCtrlViewStateManager', 'LoanService', 'PlClientService', 'ViewHistory', function ($scope, $state, $stateParams, viewState, phoneCallApplicationService, confirmDialog, Util, phoneCallAdditionalAmountApplicationCtrlViewStateManager, loanService, plClientService, viewHistory) {
    var model = viewState.model;
    $scope.loan = phoneCallAdditionalAmountApplicationCtrlViewStateManager.LOAN_TRANSFORM.transform(model.loan);
    $scope.loanDueDate = Util.jsonDateFormat(model.loan.dueDate);
    $scope.clientAvailableCreditLimit = model.client.availableCreditLimit;
    $scope.offerCalculated = false;

    $scope.clientConsents = {
        agreeWithAll: false,
        agreeWithTerms: false,
        agreeWithBik: false,
        agreeWithBig: false,
        agreeEUCB: false,
        agreePep: false,
        agreeReceiveNewsAll: false,
        agreeReceiveNewsSms: false,
        agreeReceiveNewsPhone: false,
        agreeReceiveNewsEmail: false,
        agreeSpvBroker: false,
        agreeIncredit: false
    };

    $scope.newsAllCheckboxClicked = function () {
        _.each(['agreeReceiveNewsSms', 'agreeReceiveNewsPhone', 'agreeReceiveNewsEmail'], function (key) {
            $scope.clientConsents[key] = $scope.clientConsents.agreeReceiveNewsAll;
        });
    };

    $scope.isAnyNewsConsentChecked = function () {
        return $scope.clientConsents.agreeReceiveNewsSms || $scope.clientConsents.agreeReceiveNewsPhone || $scope.clientConsents.agreeReceiveNewsEmail;
    };

    $scope.agreeWithAll = function () {
        var checked = $scope.clientConsents.agreeWithAll;
        $scope.clientConsents.agreeWithTerms = checked;
        $scope.clientConsents.agreeWithBik = checked;
        $scope.clientConsents.agreeWithBig = checked;
        $scope.clientConsents.agreeEUCB = checked;
        $scope.clientConsents.agreePep = checked;
        $scope.clientConsents.agreeSpvBroker = checked;
        $scope.clientConsents.agreeIncredit = checked;
    };

    function allMandatoryConsentsChecked() {
        return $scope.clientConsents.agreeWithTerms && $scope.clientConsents.agreeWithBig && $scope.clientConsents.agreePep && $scope.clientConsents.agreeIncredit;
    }

    $scope.calculateAdditionaAmountOffer = function () {
        if (!additionalAmountCorrect($scope.additionalAmount)) {
            $scope.offerCalculated = false;
            $scope.showServerError('Incorrect additional amount');
            return;
        }
        loanService.getAdditionalAmountOffer(model.loan.id, $scope.additionalAmount).then(function (entries) {
            $scope.additionalAmountOffer = phoneCallAdditionalAmountApplicationCtrlViewStateManager.ADDITIONAL_AMOUNT_OFFER_TRANSFORM.transform(entries.data.costs);
            $scope.offerCalculated = true;
        });
    };

    $scope.confirmAdditionalAmountApplication = function () {
        if (allMandatoryConsentsChecked() === false) {
            $scope.showServerError('Mandatory consents need to be checked!');
            return;
        }

        $scope.showOverlay();
        plClientService.addConsent($stateParams.clientId, "agreePep").then(function () {
            var applyForAdditionalAmountCommand = {
                clientId: model.client.id,
                amount: $scope.additionalAmount,
                applicationChannelType: 'PHONE'
            };
            loanService.applyForAdditionalAmount(applyForAdditionalAmountCommand).then(SuccessHandler, ErrorHandler);
        });
    };

    $scope.cancelAdditionalAmountApplication = function () {
        $scope.showOverlay();
        viewHistory.closeCurrentHistoryEntry();
    };

    function additionalAmountCorrect(amount) {
        var amountCorrect = false;
        if (amount <= $scope.clientAvailableCreditLimit && amount >= 100 && amount % 50 === 0) {
            amountCorrect = true;
        }
        return amountCorrect;
    }

    function SuccessHandler() {
        $scope.hideOverlay();
        $scope.notifySuccess('Applied for additional amount!');
        viewHistory.closeCurrentHistoryEntry();
    }

    function ErrorHandler(response) {
        $scope.hideOverlay();
        var errorMessage = response.data.message;
        $scope.showServerError('Error: ' + errorMessage);
    }
}]);
'use strict';

angular.module('adminApp').factory('PhoneCallRefinanceApplicationCtrlViewStateManager', ['LoanService', 'ClientService', 'CacheModelViewStateManager', 'EntityTransforms', '$q', '$http', function (loanService, clientService, CacheModelViewStateManager, EntityTransforms, $q, $http) {
    return CacheModelViewStateManager.create().extend({
        createViewState: function createViewState($stateParams) {
            return $q.all([clientService.getClientLastLoan($stateParams.clientId), clientService.getClient($stateParams.clientId)]).then(function (result) {
                return { model: { loan: result[0].data, client: result[1].data } };
            });
        },
        LOAN_TRANSFORM: EntityTransforms.getLoanTransform(['number', 'agreementType', 'status', 'principalBalance', 'interestBalance'])

    });
}]).controller('PhoneCallRefinanceApplicationCtrl', ['$scope', '$state', '$stateParams', '$q', 'ViewState', 'PhoneCallApplicationService', 'ConfirmDialog', 'Util', 'PhoneCallRefinanceApplicationCtrlViewStateManager', 'LoanService', 'ClientService', 'PlClientService', 'ViewHistory', function ($scope, $state, $stateParams, $q, viewState, phoneCallApplicationService, confirmDialog, Util, phoneCallRefinanceApplicationCtrlViewStateManager, loanService, clientService, plClientService, viewHistory) {
    var model = viewState.model;
    $scope.loan = model.loan;
    $scope.refinanceConditionChoosen = false;
    $scope.clientAcceptNews = model.client.acceptNews;

    $scope.consentMarketing = false;

    $scope.successMessage = 'Application: registered! Client must send approval SMS';

    $scope.clientConsents = {
        agreeWithAll: false,
        agreeWithTerms: false,
        agreeWithBik: false,
        agreeWithBig: false,
        agreeIncredit: false,
        agreeSpvBroker: false,
        agreePep: false,
        agreeReceiveNews: false
    };

    $scope.refinanceConditionSelected = function () {
        $scope.refinanceConditionChoosen = true;
        $scope.conditions = $scope.refinanceCondition;
    };

    $scope.discountPercentChanged = function () {
        $scope.activeFees = $scope.discountPercent ? $scope.loan.refinanceFeesWithDiscount : $scope.loan.refinanceFees;
    };
    $scope.discountPercentChanged();

    $scope.confirmAdditionalAmountApplication = function () {
        if (allMandatoryConsentsChecked() === false) {
            $scope.showServerError('Mandatory consents needs to be checked!');
            return;
        }

        $scope.showOverlay();

        plClientService.addConsent($stateParams.clientId, "agreePep").then(function () {
            clientService.addRefinanceDiscount($stateParams.clientId, { percent: $scope.discountPercent ? $scope.discountPercent : 0 }).then(function () {
                var applyForRefinanceCommand = {
                    clientId: $stateParams.clientId,
                    term: $scope.conditions.term
                };
                loanService.applyForRefinanceApplication(applyForRefinanceCommand).then(SuccessHandlerApplyForRefinance, ErrorHandler);
            });
        });
    };

    $scope.cancelRefinanceApplication = function () {
        $scope.showOverlay();
        viewHistory.closeCurrentHistoryEntry();
    };

    $scope.agreeWithAll = function () {
        var checked = $scope.clientConsents.agreeWithAll;
        $scope.clientConsents.agreeWithTerms = checked;
        $scope.clientConsents.agreeWithBik = checked;
        $scope.clientConsents.agreeWithBig = checked;
        $scope.clientConsents.agreeIncredit = checked;
        $scope.clientConsents.agreeSpvBroker = checked;
    };

    function allMandatoryConsentsChecked() {
        return $scope.clientConsents.agreeWithTerms && $scope.clientConsents.agreeWithBig && $scope.clientConsents.agreePep && $scope.clientConsents.agreeIncredit;
    }

    function SuccessHandlerAddDiscount() {
        $scope.notifySuccess('REFINANCE DISCOUNT ADDED!');
    }

    function SuccessHandlerApplyForRefinance() {
        $scope.hideOverlay();
        $scope.notifySuccess('APPLIED FOR REFINANCE!');
        viewHistory.closeCurrentHistoryEntry();
    }

    function ErrorHandler(response) {
        $scope.hideOverlay();
        var errorMessage = response.data.message;
        $scope.showServerError('Error: ' + errorMessage);
    }
}]);